/* General Styles */
body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #07090d;
    color: #fff;
  }
  
  .signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #07090d;
  }
  
  .signin-box {
    background: #1e1e1e;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 0px 30px 5px rgba(156, 22, 251, 0.5);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .signin-box h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #ffffff;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #a0a0a0;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #333;
    border-radius: 8px;
    background-color: #2a2a2a;
    color: #fff;
    outline: none;
    font-size: 16px;
  }
  
  .input-group input:focus {
    border-color: #6200ea;
  }
  
  .btn {
    width: 100%;
    padding: 12px;
    background: #6200ea;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn:hover {
    background: #3700b3;
  }
  
  .forgot-password {
    margin-top: 15px;
  }
  
  .forgot-password a {
    color: #bb86fc;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .forgot-password a:hover {
    color: #ffffff;
  }
  