
  
  .plan-card {
    background: #1e1e1e;
    border-radius: 12px;
    /* box-shadow: 0 0px 30px 5px rgba(156, 22, 251, 0.5); */
    transition: transform 0.2s;
  }
  
  .plan-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(156, 22, 251, 0.6);
  }
  
  .card-price {
    font-size: 2.5rem;
    margin: 20px 0;
    color: #6200ea; /* Highlight price */
  }
  
  .card-header {
    background-color: #6200ea; /* Match header with button color */
    color: white;
    border-radius: 12px 12px 0 0; /* Rounded corners on top */
  }
  
  .btn {
    width: 100%;
    padding: 12px;
    background: #6200ea;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn:hover {
    background: #3700b3; /* Darker shade on hover */
  }
  